<template>
  <div class="game-detail-container">
    <!-- 骨架屏加载状态 -->
    <template v-if="loading">
      <div class="skeleton-container">
        <!-- 标题骨架屏 -->
        <div class="skeleton-title"></div>

        <!-- 轮播图骨架屏 -->
        <div class="skeleton-carousel"></div>

        <!-- 主要内容骨架屏 -->
        <div class="skeleton-content">
          <div class="skeleton-main">
            <div class="skeleton-cover"></div>
            <div class="skeleton-info">
              <div class="skeleton-text"></div>
              <div class="skeleton-text"></div>
              <div class="skeleton-text"></div>
            </div>
          </div>
          <div class="skeleton-sidebar">
            <div class="skeleton-card"></div>
            <div class="skeleton-card"></div>
            <div class="skeleton-card"></div>
          </div>
        </div>
      </div>
    </template>

    <!-- 实际内容 -->
    <template v-else>
      <!-- 游戏标题区域 -->
      <div class="game-title-section">
        <h1 class="game-title">{{ form.name }}</h1>
        <div class="game-meta">
          <span class="release-date">发行日期：{{ form.releaseDate }}</span>
          <span class="game-region">{{ form.region }}</span>
        </div>
      </div>

      <!-- 游戏图片轮播 -->
      <div class="game-carousel-section">
        <el-carousel 
          :interval="4000" 
          type="card" 
          height="420px"
          class="game-carousel"
        >
          <el-carousel-item v-for="(item, index) in form.picdetial" :key="index">
            <img :src="item" class="carousel-image" alt="游戏截图"/>
          </el-carousel-item>
        </el-carousel>
      </div>

      <!-- 主要内容区域 -->
      <div class="main-content">
        <el-row :gutter="20">
          <!-- 左侧主要内容 -->
          <el-col :xs="24" :sm="24" :md="20" :lg="20" :xl="20">
            <!-- 游戏基本信息卡片 -->
            <el-card class="game-info-card" shadow="hover">
              <el-row :gutter="20" class="info-row">
                <!-- 游戏封面 -->
                <el-col :span="8">
                  <div class="game-cover">
                    <img :src="form.picmulu" class="cover-image" alt="游戏封面"/>
                  </div>
                </el-col>
                
                <!-- 游戏详细信息 -->
                <el-col :span="16">
                  <div class="game-details">
                    <!-- 游戏标题和发行日期 -->
                    <div class="game-header">
                      <h2 class="game-name">{{ form.name }}</h2>
                      <div class="release-info">
                        <i class="el-icon-date"></i>
                        <span>发行日期：{{ form.releaseDate }}</span>
                      </div>
                    </div>

                    <!-- 游戏评分 -->
                    <div class="rating-section">
                      <div class="rating-display">
                        <el-rate
                          v-model="form.averageRating"
                          disabled
                          show-score
                          text-color="#ff9900"
                          score-template="{value}"
                        />
                        <span class="rating-score">{{ form.averageRating }}</span>
                      </div>
                      <div class="rating-count">
                        <i class="el-icon-user"></i>
                        <span>评价人数：{{ form.totalRatingsCount }}</span>
                      </div>
                    </div>

                    <!-- 游戏标签信息 -->
                    <div class="game-tags">
                      <el-tag type="success" effect="dark" class="info-tag">
                        <i class="el-icon-game"></i> 游戏ID：{{ form.gameid }}
                      </el-tag>
                      <el-tag type="success" effect="dark" class="info-tag">
                        <i class="el-icon-collection-tag"></i> 游戏类型：{{ form.type }}
                      </el-tag>
                      <el-tag type="success" effect="dark" class="info-tag">
                        <i class="el-icon-office-building"></i> 发行商：{{ form.publisherName }}
                      </el-tag>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </el-card>

            <!-- 游戏描述卡片 -->
            <el-card class="game-description-card" shadow="hover">
              <div class="description-content" v-html="form.descriptions"></div>
            </el-card>
          </el-col>

          <!-- 右侧信息栏 -->
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <!-- 价格和购买按钮 -->
            <el-card class="price-card" shadow="hover">
              <div class="price-section">
                <span class="price-label">价格</span>
                <span class="price-value">{{ form.basePrice }}</span>
                <el-button 
                  type="primary" 
                  class="buy-btn"
                  @click="changelink(form.gameid)"
                >
                  <i class="el-icon-shopping-cart-2"></i> 前往PSN商店
                </el-button>
              </div>
            </el-card>

            <!-- 语言支持信息 -->
            <el-card class="language-card" shadow="hover">
              <div class="language-section">
                <h3 class="section-title">
                  <i class="el-icon-chat-dot-round"></i> 屏幕语言
                </h3>
                <div class="language-tags">
                  <el-tag 
                    v-for="item in language" 
                    :key="item" 
                    class="language-tag"
                  >
                    {{ item }}
                  </el-tag>
                </div>
              </div>
            </el-card>

            <!-- 配音语言信息 -->
            <el-card class="voice-card" shadow="hover">
              <div class="voice-section">
                <h3 class="section-title">
                  <i class="el-icon-microphone"></i> 配音语言
                </h3>
                <div class="voice-tags">
                  <el-tag 
                    v-for="item in splanguage" 
                    :key="item" 
                    class="voice-tag"
                  >
                    {{ item }}
                  </el-tag>
                </div>
              </div>
            </el-card>

            <!-- 区服信息 -->
            <el-card class="region-card" shadow="hover">
              <div class="region-section">
                <h3 class="section-title">
                  <i class="el-icon-location"></i> 区服信息
                </h3>
                <el-tag type="info" effect="dark" class="region-tag">
                  {{ form.region }}
                </el-tag>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: 'PSGameDetail',
  data() {
    return {
      loading: true, // 加载状态
      user: "", // 用户名
      fit: "", // 适配
      formInline: { // 表单初始数据
        user: "",
        region: "",
      },
      url: "static/img/htt/1.jpeg", // 默认图片URL
      form: {}, // 游戏详情表单
      yearMajor: {}, // 年份与专业
      input: "", // 输入框内容
      comments: [], // 评论列表
      tab: [], // 标签列表
      language: [], // 屏幕语言列表
      splanguage: [] // 语音语言列表
    };
  },
  methods: {
    /**
     * 跳转到PSN商店
     * @param {string} event - 游戏ID
     */
    changelink(event) {
      window.open("https://store.playstation.com/" + this.form.region + "/concept/" + event);
    },

    /**
     * 购买游戏
     * @param {string} id - 游戏ID
     */
    shop(id) {
      axios.get(this.$globle.allurl + "shopgame", {
        params: {
          userid: JSON.parse(localStorage.getItem("user") || "{}").id,
          gameid: id
        }
      }).then(res => {
        switch (res.data) {
          case 1:
            this.$alert('游戏已购买', '购买失败', {
              confirmButtonText: '确定',
            });
            break;
          case 2:
            this.$alert('账户余额不足', '购买失败', {
              confirmButtonText: '确定',
            });
            break;
          case 3:
            this.$alert('游戏愉快', '购买成功', {
              confirmButtonText: '确定',
            });
            break;
        }
      });
    },

    /**
     * 加载游戏详情数据
     */
    async load1() {
      const param = this.$route.query.id;

      try {
        const response = await axios.get(this.$globle.allurl + "ps/getonepsgame/" + param);
        const game = response.data;

        // 设置游戏详情数据
        this.form = game;

        // 处理语言列表
        this.language = game.screenLanguages.split("\n");
        this.splanguage = game.spokenLanguages.split("\n");

        // 处理详细图片
        this.form.picdetial = game.picdetial.split("\n");

        // 处理地区显示
        switch (game.region) {
          case "zh-hans-hk":
            this.form.region = "港服";
            break;
          case "en-us":
            this.form.region = "美服";
            break;
          case "ja-jp":
            this.form.region = "日服";
            break;
          case "en-gb":
            this.form.region = "英服";
            break;
        }
      } catch (error) {
        console.error("加载游戏详情时出错：", error);
      } finally {
        // 模拟加载延迟
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      }
    },
  },
  mounted() {
    this.load1();
  }
};
</script>

<style scoped>
/* 游戏详情容器 */
.game-detail-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f7fa;
  min-height: 100vh;
}

/* 游戏标题区域 */
.game-title-section {
  text-align: center;
  margin-bottom: 30px;
  padding: 20px;
  background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0,0,0,0.05);
}

.game-title {
  font-size: 36px;
  color: #303133;
  margin: 0 0 10px;
  font-weight: 600;
}

.game-meta {
  color: #909399;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

/* 轮播图区域 */
.game-carousel-section {
  margin-bottom: 30px;
}

.game-carousel {
  border-radius: 16px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0,0,0,0.1);
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* 主要内容区域 */
.main-content {
  display: flex;
  gap: 20px;
}

/* 游戏信息卡片 */
.game-info-card {
  margin-bottom: 20px;
  border-radius: 16px;
}

.info-row {
  display: flex;
  align-items: flex-start;
}

.game-cover {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.cover-image {
  width: 100%;
  max-width: 256px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
}

.game-details {
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.game-header {
  margin-bottom: 20px;
}

.game-name {
  font-size: 28px;
  color: #303133;
  margin: 0 0 10px;
  font-weight: 600;
}

.release-info {
  color: #909399;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.release-info i {
  margin-right: 5px;
}

/* 评分区域 */
.rating-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 8px;
}

.rating-display {
  display: flex;
  align-items: center;
  gap: 10px;
}

.rating-score {
  font-size: 24px;
  color: #ff9900;
  font-weight: 600;
}

.rating-count {
  color: #909399;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.rating-count i {
  margin-right: 5px;
}

/* 游戏标签 */
.game-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: auto;
}

.info-tag {
  font-size: 14px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
}

.info-tag i {
  margin-right: 5px;
}

/* 游戏描述卡片 */
.game-description-card {
  border-radius: 16px;
  margin-bottom: 20px;
}

.description-content {
  color: #606266;
  line-height: 1.8;
  font-size: 15px;
  text-align: justify;
}

/* 右侧信息栏 */
.price-card,
.language-card,
.voice-card,
.region-card {
  margin-bottom: 20px;
  border-radius: 16px;
}

.price-section {
  text-align: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-label {
  color: #909399;
  font-size: 14px;
  margin-bottom: 5px;
}

.price-value {
  font-size: 32px;
  color: #fa4d00;
  font-weight: 600;
  margin-bottom: 15px;
}

.buy-btn {
  width: 100%;
  padding: 12px;
  border-radius: 20px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buy-btn i {
  margin-right: 5px;
}

/* 语言和区服信息 */
.section-title {
  font-size: 16px;
  color: #303133;
  margin: 0 0 15px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.language-section,
.voice-section,
.region-section {
  padding: 15px;
}

.language-tags,
.voice-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
}

.language-tag,
.voice-tag {
  font-size: 12px;
  padding: 4px 8px;
  text-align: center;
}

.region-tag {
  width: 100%;
  text-align: center;
  padding: 8px;
  font-size: 14px;
}

/* 骨架屏样式 */
.skeleton-container {
  padding: 20px;
}

.skeleton-title {
  height: 60px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 8px;
  margin-bottom: 20px;
}

.skeleton-carousel {
  height: 420px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 16px;
  margin-bottom: 30px;
}

.skeleton-content {
  display: flex;
  gap: 20px;
}

.skeleton-main {
  flex: 1;
  display: flex;
  gap: 20px;
}

.skeleton-cover {
  width: 256px;
  height: 256px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 8px;
}

.skeleton-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.skeleton-text {
  height: 20px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 4px;
}

.skeleton-sidebar {
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skeleton-card {
  height: 150px;
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background-size: 400% 100%;
  animation: skeleton-loading 1.4s ease infinite;
  border-radius: 8px;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* 响应式设计 */
@media screen and (max-width: 768px) {
  .game-detail-container {
    padding: 10px;
  }
  
  .game-title {
    font-size: 28px;
  }
  
  .game-carousel {
    height: 300px !important;
  }
  
  .main-content {
    flex-direction: column;
  }
  
  .info-row {
    flex-direction: column;
  }
  
  .game-cover {
    margin-bottom: 20px;
  }
  
  .rating-section {
    flex-direction: column;
    gap: 10px;
  }
  
  .skeleton-main {
    flex-direction: column;
  }
  
  .skeleton-cover {
    width: 100%;
    height: 200px;
  }
  
  .skeleton-sidebar {
    width: 100%;
  }
}
</style>
